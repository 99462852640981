import React, { useEffect } from "react";
import Helmet from "react-helmet";

const SEO_DATA = {
  description: "",
  title: "",
  url: "",
  author: "",
  keywords: [
    "wypożyczalnia samochodów",
    "auta z instalacją LPG",
    "auta na wynajem",
  ],
  twitter: {
    id: "",
    img: "",
  },
  facebook: {
    id: "",
    img: "",
  },
};

const SEO = ({ seo }) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.googletagmanager.com/gtag/js?id=G-H8KBCNED8X";
    script.async = true;
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "G-H8KBCNED8X");
    };

    document.body.appendChild(script);
    let createdPopup = false;
    let popup = null;
    if (!localStorage.getItem("cookieconsent")) {
      popup = document.createElement("div");
      popup.className = "cookieconsent";
      document.body.appendChild(popup);
      popup.style.position = "fixed";
      popup.style.padding = "20px";
      popup.style.left = "0";
      popup.style.right = "0";
      popup.style.bottom = "0";
      popup.style.lineHeight = "25px";
      popup.style.background = "#dedede";
      popup.style.color = "#111";
      popup.style.textAlign = "center";
      popup.style.zIndex = "999";
      popup.innerHTML = `
      Używając tej strony wyrażasz zgodę na używanie plików cookies, zgodnie z aktualnymi ustawieniami przeglądarki oraz akceptujesz <a href="/polityka-prywatnosci">Politykę Prywatności</a>.
        <button style="color:#fff; background: #184377; border-radius: 4px; padding: 6px 10px;display:inline-block; margin:0.25rem 0.5rem;border:none;text-decoration:none;text-transform:uppercase">Akceptuję</button>
      `;
      createdPopup = true;
      document
        .querySelector(".cookieconsent button")
        .addEventListener("click", function (e) {
          e.stopPropagation();
          e.preventDefault();
          try {
            document.body.removeChild(popup);
          } catch (e) {}
          localStorage.setItem("cookieconsent", true);
        });
    }

    return () => {
      try {
        document.body.removeChild(script);
        if (createdPopup) {
          document.body.removeChild(popup);
        }
      } catch (e) {}
    };
  }, []);

  let seoData = {
    ...SEO_DATA,
    ...(seo || {}),
  };
  return (
    <Helmet>
      {seoData.url ? <link rel="canonical" href={seoData.url} /> : null}

      <meta property="og:url" content={seoData.url} />
      <meta property="fb:app_id" content={seoData.facebook.id} />
      <meta property="og:title" content={seoData.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={seoData.url} />
      <meta property="og:image" content={seoData.facebook.img} />
      <meta property="og:description" content={seoData.description} />

      {/* <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@hackinoutco" />
      <meta name="twitter:site" content="@hackinoutco" /> */}
      <meta name="twitter:title" content={seoData.title} />
      <meta name="twitter:description" content={seoData.description} />
      <meta name="twitter:domain" content={seoData.url} />
      <meta name="twitter:image:src" content={seoData.img} />

      <meta name="description" content={seoData.description} />
      <meta name="keywords" content={seoData.keywords.join(", ")} />
      <meta name="author" content={seoData.author} />
      <title>{seoData.title}</title>
      <html lang="pl" />
    </Helmet>
  );
};

export default SEO;
