import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import {
  ThemeProvider as ThemeProvider2,
  createMuiTheme,
} from "@material-ui/core/styles";

import SEO from "@common/SEO";

import theme from "@styles/theme";
import GlobalStyles from "@styles/GlobalStyles";

const theme2 = createMuiTheme({
  palette: {
    primary: {
      main: "#2766c4",
    },
    secondary: {
      main: "#2ecc2e",
      contrastText: "white",
    },
  },
});

const Layout = ({ seo, children }) => (
  <ThemeProvider theme={theme}>
    <ThemeProvider2 theme={theme2}>
      <>
        <SEO seo={seo} />
        <GlobalStyles />
        {children}
      </>
    </ThemeProvider2>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
