const theme = {
  font: {
    primary: `system-ui,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji`,
    secondary: `system-ui,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji`,
  },
  font_size: {
    xxsmall: "font-size: 12px; line-height: 14px",
    xsmall: "font-size: 14px; line-height: 18px",
    small: "font-size: 16px; line-height: 22px",
    regular: "font-size: 18px; line-height: 26px",
    large: "font-size: 22px; line-height: 28px",
    larger: "font-size: 26px; line-height: 34px",
    xlarge: "font-size: 30px; line-height: 38px",
  },
  color: {
    white: {
      regular: "#FFFFFF",
      dark: "#f4f4f4",
    },
    black: {
      lighter: "#888888",
      light: "#444444",
      regular: "#222222",
    },
    primary: {
      regular: "#184377",
      light: "#1d5598",
    },
    secondary: {
      regular: "#F68B1F",
      light: "#ff9a35",
    },
  },
  screen: {
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
  },
};

export default theme;
